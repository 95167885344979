<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Swiper as SwiperClass } from 'swiper'
import { Models } from '~/types/models'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { PropType } from 'nuxt/dist/app/compat/capi'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource[]>,
        required: true,
    },
    seo: {
        type: Object as PropType<{
            h1: string
            h2: string
        }>,
        required: true,
    },
})

const pathMap = {
    collection: '/series/',
    exhibit: '/exhibiciones/',
    special_project: '/proyectos-especiales/',
}
</script>

<template>
    <section class="hero">
        <div class="hero__wrapper">
            <Swiper
                ref="slideSwiper"
                class="hero__swiper"
                :modules="[Navigation]"
                slides-per-view="auto"
                :space-between="0"
                :navigation="{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }"
                @slide-change="(s) => $emit('collection', data[s.activeIndex])"
            >
                <SwiperSlide v-for="(item, key) in data" :data-hash="key" class="swiper__content">
                    <div class="swiper__head">
                        <NuxtLink :to="pathMap[item.type] + item.slug"
                            ><h1 v-html="item.title"></h1></NuxtLink
                        >
                        <NuxtLink :to="pathMap[item.type] + item.slug"
                            ><p v-html="item.content"></p></NuxtLink
                        >
                    </div>
                    <img
                        class="swiper__content-img"
                        :src="item.cover || ''"
                        :alt="item.title || ''"
                        :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                    />
                    <div v-if="item.artists.data[0]" class="swiper__content-author">
                        <NuxtLink
                            :to="'/artistas/' + item.artists.data[0].slug"
                            class="swiper__content-author-wrapper"
                        >
                            <div class="swiper__content-author-img">
                                <img :src="item.artists.data[0].avatar" :alt="item.artists.data[0].name" />
                            </div>
                            <div class="swiper__content-author-info">
                                <p>Artista</p>
                                <p class="swiper__content-author-artist">
                                    {{ item.artists.data[0].name }} {{ item.artists.data[0].last_name }}
                                </p>
                            </div>
                        </NuxtLink>
                    </div>
                </SwiperSlide>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </Swiper>
        </div>
    </section>
</template>

<style lang="postcss" scoped>
.hero {
    &__wrapper {
        @apply lg:container w-full relative;

        & .swiper {
            @apply relative;
            &__head {
                @apply relative z-20 text-white flex flex-col space-y-2.5 lg:space-y-0 text-center mt-8 container;

                & h1 {
                    @apply line-clamp-2 leading-[1.1] font-Pathway font-extrabold text-[45px] lg:text-[65px];
                }
                & p {
                    @apply line-clamp-3 font-Inter font-semibold leading-[1.4] text-[18px] lg:text-[25px];
                }
            }
            &__content {
                @apply w-full h-[379px] lg:h-[547px] relative text-[10px];
                &-img {
                    @apply absolute inset-0 h-full w-full brightness-75 object-cover;
                }
                &-author {
                    @apply text-white py-6;
                    &-wrapper {
                        background-color: rgba(0, 0, 0, 0.55);
                        @apply flex items-center gap-3 lg:gap-5 backdrop-blur-sm mx-auto px-5 lg:px-10 p-3 lg:p-4 w-fit;
                    }
                    &-img {
                        @apply block flex-none rounded-full h-12 w-12;
                        & img {
                            @apply rounded-full h-full w-full object-cover;
                        }
                    }
                    &-info {
                        @apply flex flex-col gap-1 text-xs md:text-sm border-l px-3 font-Inter;
                    }
                    &-artist {
                        @apply font-Pathway font-bold text-sm md:text-xl;
                    }
                }
            }
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    @apply z-[50] bg-black border border-black 
    bg-opacity-80 p-7 lg:p-10 py-7 lg:py-10 text-white 
    mt-[130px] lg:mt-36 font-bold;
    &::after {
        @apply text-[18px] lg:text-[25px];
    }
}
.swiper-button-prev {
    @apply -ml-3 lg:ml-10;
}
.swiper-button-next {
    @apply -mr-3 lg:mr-10;
}
</style>
