<script setup lang="ts">
import { PropType } from 'vue'
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource>,
        required: true,
    },
    type: {
        type: String as PropType<'homepage' | 'collection'>,
        default: 'collection',
    },
    mobile: {
        type: Boolean,
        default: false,
    },
})

const styles = computed(() => {
    return {
        image:
            props.type === 'homepage' && props.mobile
                ? 'h-[390px]'
                : props.type === 'homepage'
                ? 'md:h-[485px] lg:h-[610px]'
                : 'h-[530px] lg:h-[756px]',
        details: props.type === 'homepage' ? 'gap-3' : 'flex-col lg:flex-row gap-2 lg:gap-5',
        detailsTitle:
            props.type === 'homepage'
                ? 'border-r pr-2 lg:pr-0 text-xs md:text-lg'
                : 'border-b pb-3 text-sm md:text-xl',
        detailsDescription: props.type === 'homepage' ? 'w-[45%] lg:w-[50%] shrink-0' : 'pt-2 ',
    }
})
</script>

<template>
    <NuxtLink :to="'/series/' + data.slug" class="piece">
        <div class="piece__image" :class="styles.image">
            <img :src="data.cover" :alt="data.title" :onerror="`this.src=&quot;/no-image.jpg&quot;`" />
            <div class="image__footer">
                <div class="image__footer-wrapper">
                    <div :class="styles.details" class="image__footer-details">
                        <p :class="styles.detailsTitle" class="image__footer-title" v-html="props.data.title"></p>
                        <div v-html="props.data.content" :class="styles.detailsDescription" class="image__footer-info">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLink>
</template>

<style lang="postcss" scoped>
.piece {
    &__image {
        @apply w-full relative;
        & img {
            @apply w-full h-full object-cover;
        }
        & .image__footer {
            @apply absolute bottom-0 inset-x-0 w-full lg:w-fit lg:mx-auto;
            &-wrapper {
                background-color: rgba(0, 0, 0, 0.55);
                @apply items-center justify-center text-white py-6 sm:m-10  
                flex flex-col lg:flex-row backdrop-blur-sm flex-wrap gap-y-5 gap-x-20 px-5;
            }
            &-details {
                @apply flex items-center;
            }
            &-title {
                @apply line-clamp-3 font-Pathway font-bold  lg:border-none lg:pb-0 mr-auto;
            }
            &-info {
                @apply line-clamp-3  text-xs md:text-sm lg:border-l lg:pt-0 lg:px-3 font-Inter;
            }
        }
    }
}
</style>
