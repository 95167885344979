<script setup lang="ts">
import { Models } from '~/types/models'

const { PAGE } = useContentService()

const { data: pageData } = await useAsyncData('homepage-index', () =>
    PAGE<{ data: Models.HomePageResource }>('home')
)

const seoContent = {
    h1: pageData.value?.data.seo.h1,
    h2: pageData.value?.data.seo.h2,
}

useHead({
    title: pageData.value?.data.seo.title,
    meta: [
        {
            name: 'title',
            content: pageData.value?.data.seo.title,
        },
        {
            name: 'description',
            content: pageData.value?.data.seo.description,
        },
        {
            name: 'keywords',
            content: pageData.value?.data.seo.keywords,
        },
        {
            name: 'og:title',
            content: pageData.value?.data.seo.title,
        },
        {
            name: 'og:description',
            content: pageData.value?.data.seo.description,
        },
        {
            name: 'twitter:title',
            content: pageData.value?.data.seo.title,
        },
        {
            name: 'twitter:description',
            content: pageData.value?.data.seo.description,
        },
    ],
})

const pieceCollection = ref(pageData.value?.data.featureds.slider_posts[0])
const slicedCollections = ref(pageData.value?.data.featureds.slider_posts.slice(1) || [])

const HeroCollection = ref<Models.CollectionResource | null | undefined>(
    pageData.value ? pageData.value.data.featureds.collections.at(0) : null
)

const logData = (data: Models.CollectionResource) => {
    HeroCollection.value = data
}
</script>

<template>
    <main v-if="pageData" class="index">
        <Thepiece
            v-if="pageData.data.featureds.piece_of_week"
            class="index__piece"
            :data="pageData.data.featureds.piece_of_week"
        />
        
        <UtilsServerContent
            v-if="pageData.data.featureds.first_content"
            class="index__content"
            :data="pageData.data.featureds.first_content"
            classes="border-t border-black"
        />
        <UtilsServerContent
            v-if="pageData.data.featureds.second_content"
            class="index__content"
            :data="pageData.data.featureds.second_content"
            classes="border-t border-black"
        />
        
        <HomepageHero
            v-if="pageData.data.featureds.collections.length"
            class="index__hero"
            :data="pageData.data.featureds.collections"
            :seo="seoContent"
            @collection="logData"
        />
        <HomepageSwiper v-if="HeroCollection" class="index__swiper" :data="HeroCollection" />

        <!-- TODO: Habilitar newsletter -->
        <!-- <Newsletter /> -->

        <HomepageCollections v-if="pieceCollection" :data="slicedCollections" :piece="pieceCollection" />
        <Videos
            v-if="pageData.data.featureds.videos && pageData.data.featureds.videos.length"
            class="index__videos"
            :data="pageData.data.featureds.videos"
        />
        <ContactUs />
    </main>
</template>

<style lang="postcss" scoped>
.index {
    &__videos {
        @apply py-10;
    }
    &__piece {
        @apply pb-10 lg:pb-16 lg:pt-10 lg:border-t border-black container;
    }
}
</style>
