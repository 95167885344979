<script setup lang="ts">
import { PropType } from 'nuxt/dist/app/compat/capi'
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource>,
        required: true,
    },
})

const show = ref(true)

const toggle = () => {
    show.value = !show.value
}
</script>

<template>
    <section class="home-swiper">
        <button @click="toggle" class="home-swiper__toggle">
            <UnoIcon class="i-mdi:chevron-down" :class="show ? 'rotate-180' : ''" />
        </button>
        <SwiperCollection
            :class="show ? 'h-[366px] lg:h-[439px] py-10' : 'h-0'"
            class="home-swiper__data"
            :data="data"
        />
    </section>
</template>

<style lang="postcss" scoped>
.home-swiper {
    @apply container relative z-[50];
    &__toggle {
        @apply transition-all w-16 lg:w-20 left-1/2 -translate-x-1/2 rounded-t-full bg-white text-3xl lg:text-4xl absolute inset-x-0 bottom-full  h-8 lg:h-10 text-black;

        transition: all 0.3s ease-in-out;
    }
    &__data {
        @apply relative z-10 flex-none transition-all overflow-hidden;
    }
}
</style>
