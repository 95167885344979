<script setup lang="ts">
import { PropType } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

import { useModal } from '@/stores/modal'

const breakpoints = useBreakpoints(breakpointsTailwind)

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource>,
        required: true,
    },
})

const pathMap = {
    collection: '/series/',
    exhibit: '/exhibiciones/',
    special_project: '/proyectos-especiales/',
}

const modalStore = useModal()

const openDialog = (item: any) => {
    modalStore.setModal({
        info: item,
        name: 'ModalFeaturedWork',
    })
}
</script>

<template>
    <div>
        <Swiper
            v-if="breakpoints"
            class="collection"
            :modules="[Navigation]"
            slides-per-view="auto"
            :space-between="breakpoints.isGreaterOrEqual('lg') ? 30 : 15"
        >
            <SwiperSlide
                v-for="(item, key) in data.artworks.data"
                :data-hash="key"
                class="collection__content"
            >
                <article>
                    <picture @click="() => openDialog(item.cover)">
                        <img
                            :src="item.cover || ''"
                            :alt="item.title"
                            :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                    /></picture>
                    <NuxtLink :to="pathMap[data.type] + data.slug"
                        ><h3>{{ item.title }}</h3></NuxtLink
                    >
                    <p>{{ item.description }}</p>
                </article>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<style lang="postcss" scoped>
.collection {
    @apply w-full;
    &__content {
        @apply w-[150px]  lg:w-[220px];
        & article {
            @apply space-y-2;
        }
        & picture {
            @apply block w-[150px] h-[150px] lg:w-[220px] lg:h-[220px] shadow-xl;
            & img {
                @apply w-full h-full block object-cover;
            }
        }
        & a {
            @apply block;
            & h3 {
                @apply font-Pathway font-bold text-[16px] lg:text-[17px] pt-2 line-clamp-2;
            }
        }
        & p {
            @apply font-Inter line-clamp-4 text-sm text-[#929292];
        }
    }
}
</style>
