<script setup lang="ts">
import { PropType } from 'vue'
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource>,
        required: true,
    },
    type: {
        type: String as PropType<'homepage' | 'collection'>,
        default: 'collection',
    },
    mobile: {
        type: Boolean,
        default: false,
    },
})

const styles = computed(() => {
    return {
        image:
            props.type === 'homepage' && props.mobile
                ? 'h-[390px]'
                : props.type === 'homepage'
                ? 'md:h-[485px] lg:h-[610px]'
                : 'h-[530px] lg:h-[756px]',
        details: props.type === 'homepage' ? 'gap-3' : 'flex-col lg:flex-row gap-2 lg:gap-5',
        detailsTitle:
            props.type === 'homepage'
                ? 'border-r pr-2 lg:pr-0 text-xs md:text-lg'
                : 'border-b pb-3 text-sm md:text-xl',
        detailsDescription: props.type === 'homepage' ? 'w-[45%] lg:w-[50%] shrink-0' : 'pt-2 ',
    }
})
const artist = props.data.artists.data[0] as Models.ArtistResource

const pathMap = {
    collection: '/series/',
    exhibit: '/exhibiciones/',
    special_project: '/proyectos-especiales/',
}
</script>

<template>
    <div class="piece">
        <div class="piece__image" :class="styles.image">
            <NuxtLink :to="pathMap[data.type] + data.slug"
                ><img :src="data.cover" :alt="data.title" :onerror="`this.src=&quot;/no-image.jpg&quot;`"
            /></NuxtLink>
            <div class="image__footer">
                <div class="image__footer-wrapper">
                    <div v-if="artist" class="image__footer-author">
                        <NuxtLink :to="'/artistas/' + artist.slug"
                            ><img
                                class="image__footer-author-img"
                                :src="artist.avatar"
                                :alt="artist.name"
                                :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                        /></NuxtLink>
                        <div class="image__footer-info">
                            <p>Artista</p>
                            <NuxtLink :to="'/artistas/' + artist.slug"
                                ><p class="image__footer-artist">
                                    {{ artist.name }} {{ artist.last_name }}
                                </p></NuxtLink
                            >
                        </div>
                    </div>
                    <div :class="styles.details" class="image__footer-details">
                        <NuxtLink :to="pathMap[data.type] + data.slug" class="image__footer-title"
                            ><h3 :class="styles.detailsTitle">
                                {{ props.data.title }}
                            </h3></NuxtLink
                        >
                        <div :class="styles.detailsDescription" class="image__footer-info" v-html="props.data.content">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.piece {
    @apply block;
    &__image {
        @apply w-full relative;
        & img {
            @apply w-full h-full object-cover;
        }
        & .image__footer {
            @apply absolute bottom-0 inset-x-0 w-full lg:w-fit lg:mx-auto;
            &-wrapper {
                background-color: rgba(0, 0, 0, 0.55);
                @apply flex flex-col text-white py-6 sm:m-10 lg:flex-row lg:items-center lg:justify-center backdrop-blur-sm flex-wrap gap-y-5  px-5;
            }
            &-author {
                @apply flex-none lg:w-1/2 flex items-center gap-5;
            }
            &-author-img {
                @apply rounded-full h-12 w-12;
            }
            &-artist {
                @apply font-Pathway font-bold text-sm md:text-xl;
            }
            &-details {
                @apply lg:w-1/2 flex-none flex lg:items-center;

                a {
                    @apply w-full lg:w-auto;
                }
            }
            &-title {
                @apply block flex-none w-1/2 mr-auto;
                h3 {
                    @apply md:line-clamp-1 font-Pathway font-bold lg:border-none lg:pb-0;
                }
            }
            &-info {
                @apply md:line-clamp-2 text-xs md:text-sm lg:border-l lg:pt-0 lg:px-3 font-Inter;
            }
        }
    }
}
</style>
